<script>
import api from '@/command/api'
import TemplateHeaderTable from '@/components/TemplateHeaderTable'

export default {
  name: 'setMain',
  data() {
    return {
      ...api.command.getState(),
      isAll: true // 判断当前是所有数据(false)还是已添加数据(true)
    }
  },
  mounted() {
    // this.setInit()
    this.getTableAddData()
  },
  methods: {
    // 判断类型调不同的分页接口
    setInit() {
      let { moduleType } = this.$route.query
      if (moduleType === 'SZNC') {
        this.getDataInit({
          url: '/turntable/getSzncMainBodyList'
        })
      }
      if (moduleType === 'JG' || moduleType === 'NQB') {
        this.getDataInit({
          url: '/trace/H5/handFarm/getMblCompanyProduct',
          host: '/cpi'
        })
      }
    },
    // 初始化列表数据
    getDataInit({ url, host, paramsValue }) {
      api.command.getList.call(
        this,
        {
          url: url,
          current: 1,
          paramsValue: { ...paramsValue, host: host }
        },
        host
      )
    },
    // 批量删除主体
    handleBatchDelete(dataArr) {
      let { turnId } = this.$route.query
      let ids = dataArr.map(e => e.id).join(',')
      let str = turnId ? 'turntable' : 'redEnvelop'
      return api.command.del
        .call(this, {
          url: `/${str}/bodyDel/${ids}`,
          isPost: false
        })
        .then(() => {
          this.getTableAddData()
        })
    },
    // 批量添加主体
    handleBatchAdd(dataArr) {
      let { turnId, redId } = this.$route.query
      let bodyList = dataArr.map(e => {
        return {
          ...e,
          mainBodyId: e.companyId
        }
      })
      let str = turnId ? 'turntable' : 'redEnvelop'
      return api.command.editPost.call(this, {
        url: `/${str}/bindMainBody`,
        params: { bodyList, turnId, redId },
        isPost: false
      })
    },
    // 获取已添加的主体列表
    getTableAddData() {
      let { turnId, redId } = this.$route.query
      this.getDataInit({
        url: '/turntable/getRelationMainBodyList',
        paramsValue: {
          turnId,
          redId
        }
      })
    },
    getHeader() {
      return [
        {
          name: '主体名称',
          type: 'input',
          key: 'mainName'
        },
        {
          label: '',
          type: 'text'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'mainName',
          title: '主体名称',
          align: 'left',
          sorter: (a, b) => a.mainName.length - b.mainName.length
        },
        {
          dataIndex: 'sysName',
          title: '系统',
          align: 'left'
        },
        {
          dataIndex: 'address',
          title: '位置',
          align: 'left'
        },
        {
          dataIndex: 'creditCode',
          title: '统一社会信用代码',
          align: 'left'
        }
      ]
    },
    getButton() {
      return [
        {
          name: '批量添加主体',
          type: 'primary',
          display: !this.isAll,
          isBatch: true,
          style: {
            border: '1px solid #18be6b',
            color: '#18be6b'
          },
          onClick: dataArr => {
            return this.handleBatchAdd(dataArr)
          }
        },
        {
          name: '批量删除主体',
          type: 'danger',
          display: this.isAll,
          isBatch: true,
          onClick: dataArr => {
            return this.handleBatchDelete(dataArr)
          }
        }
      ].filter(e => e.display)
    },
    renderRightButton() {
      return [
        {
          name: `显示${!this.isAll ? '已有' : '所有'}主体`,
          type: 'normal',
          onClick: () => {
            if (!this.isAll) {
              this.getTableAddData()
            } else {
              this.setInit()
            }
            this.isAll = !this.isAll
          }
        }
      ]
    },
    getTitle() {
      return (
        <div class="alert-box">
          <svg
            t="1652687727500"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="7488"
            width="25"
            height="25"
          >
            <path
              d="M510.976694 146.304134c-201.970968 0-365.695866 163.728992-365.695866 365.695866s163.728992 365.695866 365.695866 365.695866 365.695866-163.728992 365.695866-365.695866S712.947661 146.304134 510.976694 146.304134L510.976694 146.304134zM480.489332 329.151555c0-16.82827 13.631462-30.475082 30.475082-30.475082 16.844643 0 30.472012 13.646811 30.472012 30.475082l0 216.70146c0 16.82827-13.627369 30.475082-30.472012 30.475082-16.84362 0-30.475082-13.646811-30.475082-30.475082L480.489332 329.151555 480.489332 329.151555zM510.976694 694.847421c-23.663956 0-42.846854-19.178805-42.846854-42.842761s19.182898-42.846854 42.846854-42.846854c23.663956 0 42.846854 19.182898 42.846854 42.846854C553.823548 675.664523 534.64065 694.847421 510.976694 694.847421L510.976694 694.847421zM510.976694 694.847421"
              p-id="7489"
              fill="#1890ff"
            ></path>
          </svg>
          <span class="span1">已添加</span>
          &nbsp; &nbsp;
          <span class="span2">0</span>
          &nbsp; &nbsp;
          <span class="span1">个主体</span>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <span class="span3">清空</span>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <span class="span3">显示已添加主体</span>
        </div>
      )
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
        rightButton={this.renderRightButton()}
        // showTitle={this.getTitle()}
      />
    )
  }
}
</script>
<style lang="less" scoped>
.alert-box {
  width: 100%;
  height: 40px;
  border: 1px solid #abdcff;
  background-color: #effaff;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;

  .span1 {
    font-family: 'PingFang SC 中等', 'PingFang SC';
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: rgb(81, 90, 110);
  }

  .span2 {
    display: flex;
    font-family: 'PingFang SC 中等', 'PingFang SC';
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: rgb(51, 153, 255);
  }

  .span3 {
    display: flex;
    font-family: 'PingFang SC 中等', 'PingFang SC';
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: rgb(51, 153, 255);
    cursor: pointer;
  }
}
</style>
